import React, { useRef, useEffect } from "react";
import Heading from "../common/heading/Heading";
import "./about.css";
import { homeAbout } from "../../dummydata";
import Awrapper from "./Awrapper";

const AboutCard = () => {
  const mainContentRef = useRef(null);

  useEffect(() => {
    mainContentRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <section className="aboutHome" ref={mainContentRef}>
        <div className="container flexSB">
          <div className="left row">
            <img src="./images/about.webp" alt="" />
          </div>
          <div className="right row">
            <Heading subtitle="LEARN ANYTHING" title="Benefits About IT Course Expertise" />
            <div className="items">
              {homeAbout.map((val, index) => {
                return (
                  <div key={index} className="item flexSB">
                    <div className="img">
                      <img src={val.cover} alt="" />
                    </div>
                    <div className="text">
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  );
};

export default AboutCard;
